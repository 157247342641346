.about {
    &__intro {
        background-image: url('./images/about-page-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 50%;
        height: 100%;
        background-position: right;
    }

    &__intro-text {
        margin-left: 55%;
        right: 5%;
    }

    @include media-breakpoint-between(xs, lg){
        &__intro {
            background-image: url('./images/about-page-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            top: 30vh;
            height: 80%;
            background-position: right;
        }
    
        &__intro-text {
            width: 100%;
            right: 0%;
            margin-left: 0%;
            padding-bottom: 100vh !important;
        }
    }
    
}