.Footer {
    &__top {
        background-color: var(--bs-dark);
        color: #FFF !important;
        padding: 5vh 0;
        font-size: 1rem;

        a {
            color: inherit;
        }

        a:hover {
            color: var(--bs-primary);
        }

        &__contact {

            .fa {
                font-size: 1rem;
                padding-right: .8vw;
            }

            li {
                margin-bottom: .5vh;
            }
            
        }

        &__social_media {
            .fa {
                font-size: 1.5rem;
            }

            li {
                display: inline-block;
                padding-right: .8vw;
            }
        }
    }
    &__bottom {
        font-size: .9rem;
        background-color: $gray-800;
        color: #fff;
    }
}