.HomePage {
    &__categories img {
        width: 300px;
        vertical-align: middle;
    }
    
    &__reviews {
        &__img {
            max-width: 100%;
            width: 450px;
            text-align: center;
        }
    }
}