.ExitModal {
    .modal-content {
        margin-top: 30vh;
        background-image: url('./BudapestExitPopup.jpg');
        background-position: right;
        background-size: 100%;
        color: #FFF;
        font-size: 1.5rem;
        line-height: 2.5rem;
        background-repeat: no-repeat;
    }

    .modal-header, .modal-footer {
        border: 0px;
    }

    .modal-dialog {
        max-width: 70vw;

        @include media-breakpoint-between(xs, md){
            max-width: 90vw;
        }
    }

    &__firstLine {
        font-weight: bold;
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    &__secondLine {
        font-family: "Nerko One";
        font-size: 4rem;
        text-shadow: 4px 4px 0px theme-color();
    }
}