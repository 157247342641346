.BookingPage {
    &__box, &__box_small {
        @include box;
    }

    &__box_small {
        @include small-box;
    }

    &__summary {
        &__category {
            img {
                max-width: 300px;

                @include media-breakpoint-between(xs, md){
                    width: 80%;
                    margin-left: 10%;
                    margin-right: 10%;
                }
            }
        }
    }

    &__step2__child_seat { 
        z-index: 7;
        cursor: default;
        position: absolute;
        left: 0;
        top: -webkit-calc(100% + 4px);
        top: -moz-calc(100% + 4px);
        top: calc(100% + 4px);
        width: 100%;
        padding: 16px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 16px 24px rgba(0,0,0,.1), 0 8px 16px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.15), 0 0 1px rgba(0,0,0,.25);
    }

    .react-datepicker-wrapper {
        display: block;
    }
}

@import "~react-datepicker/dist/react-datepicker.css";