.SearchSection {
    //height: 45vh;
    background: url("../../common/Images/banner.jpg") fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    color: #FFF;

    &__info {
        color: #FFF;
        font-size: 1rem;

        &__icon {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-between(xs, sm){
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    &__layer {
        background: rgba(0,0,0, .3);
        width: 100%;
        height: 100%;
        //padding: 10vh 0;
    }

    &__promo h1 {
        font-weight: bold;
        font-size: 2.5rem;
        color: #fff;
        text-transform: uppercase;
        line-height: 2.65rem;

        @include media-breakpoint-between(xs, sm){
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

.BookingSearchSection {
    &__form {
        &__suggestions {
            color: #000;
            &_big {
                width: 100% !important;
            }
        }
    }
}