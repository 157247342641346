.activities {
    &__box {
        background-size: cover;
        width: 100%;
        color: #FFF;
        display: flex;
        justify-content: center;  /* Centers content horizontally */
        height: 100%;
        min-height: 320px;
        align-items: center;      /* Centers content vertically */

        @include media-breakpoint-between(xs, md){
            height: 100vh;
        }

        &--fishermans-bastion {
            background-image: url('../../../public/images/activities/fishermans-bastion.jpg');
        
            @include media-breakpoint-between(xs, md){
                background-image: url('../../../public/images/activities/fishermans-bastion-mobile.jpg');
            }
        }

        &--szechenyi-baths {
            background-image: url('../../../public/images/activities/szechenyi-bath.jpg');

            @include media-breakpoint-between(xs, md){
                background-image: url('../../../public/images/activities/szechenyi-bath-mobile.jpg');
            }
        }

        &--danube-cruise {
            background-image: url('../../../public/images/activities/cruise-budapest.jpg');

            @include media-breakpoint-between(xs, md){
                background-image: url('../../../public/images/activities/cruise-budapest-mobile.jpg');
            }
        }

        &--hungarian-parliament {
            background-image: url('../../../public/images/activities/hungarian-parliament.jpg');

            @include media-breakpoint-between(xs, md){
                background-image: url('../../../public/images/activities/hungarian-parliament-mobile.jpg');
            }
        }
    }

    &__fade {
        background: rgba(0,0,0,0.75);
        width: 100%;
        min-height: 320px;
        height: 100%;
        display: block;
    }
}