.BookingSearchSection {
    //height: 45vh;
    background: url("../../../../common/Images/banner.jpg") fixed;

    &__layer {
        background: rgba(0,0,0, .3);
        width: 100%;
        height: 100%;
        padding: 6vh 0;
    }

    &__h1 {
        font-weight: bold;
        font-size: 2.5rem;
        color: #FFF;
        text-transform: uppercase;
        line-height: 5vh;
    }

    &__form {
        &__suggestions {
            @include media-breakpoint-between(xs, lg){
                width: 95vw !important;
                max-width: initial;
            }
        
            &__item {
                border-bottom: 1px solid lighten(#000, 95);
                padding-bottom: .5rem;

                &--active {
                    background-color: darken(#FFF, 5);
                    cursor: pointer;
                    border-bottom: 1px solid lighten(#000, 95);
                    padding-bottom: .5rem;
                }
            }

            &__to {
                left: 35%;

                @include media-breakpoint-between(xs, lg){
                    left: 0%;
                    top: 7.5rem;
                }
            }
        }
        
    }
}