.Header {
    &__navigation {

        @include media-breakpoint-between(xs, lg){
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid #000;
        }

        :not(:first-child) {
            margin-left: 1rem;
        }

        @include media-breakpoint-between(xs, lg){
            :not(:first-child) {
                margin-left: 0rem;
            }
        }

        &__link {
            color: #000;
            position: relative;

            @include media-breakpoint-between(xs, lg){
                font-size: 2.5rem;
                line-height: 2.5rem;
                margin-top: .8rem;
            }

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background: var(--bs-primary);
                transition: width 0.3s;
                position: absolute;
                bottom: -2px; // Adjust as needed for proper placement
                left: 0;
            }

            &:hover::after,
            &:focus::after,
            &.active::after {
                width: 100%;

                @include media-breakpoint-between(xs, lg){
                    width: 0;
                }
            }
        }

        .active, a:hover {
            color: var(--bs-primary) !important;
        }

        
    }

    img {
        width: 250px;
    }

    .navbar {
        padding: .8rem 0;
    }
}