h1 {
    font-weight: 700;
}

.bg-gray {
    background-color: $primary-background;
}

ul.no-style {
    list-style: none;
    padding-left: 0;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
    border: 0px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

@include media-breakpoint-between(xs, md){
    .input-group-responsive {
        display: block;
        flex-wrap: initial;
        align-items: initial;
    
        .form-control {
            flex: initial;
            width: 100%;
            border-radius: 0.25rem !important;
            margin-top: 1rem;
        }
    
        .input-group-prepend {
            display: none;
        }
    }
}

.navbar {
    padding: 0;
}

@mixin box {
    -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.30);
    box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.30);
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;

    @include media-breakpoint-between(xs, md){
        width: 100%;
    }

    &__content {
        padding: 2.5rem 5rem 2rem 5rem;

        @include media-breakpoint-between(xs, md){
            padding: 2rem 1rem;
        }

        &__category_image {
            @include media-breakpoint-between(xs, md){
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
            }
        }
    }
}

@mixin small-box {
    width: 60%;

    @include media-breakpoint-between(xs, md){
        width: 100%;
    }
}

img {
    max-width: 100%;
}

// .font-weight-bold, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, b {
//     color: $heading-colors;
// }

html { 
    font-size: $default-font-size-px; 
}

.form-control, .form-control-select {
    font-weight: 400;
}

a {
    text-decoration: none;
}

.side-panel {
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.sticky-top {
    top: 20px; /* Adjust sticky top offset if needed */
}