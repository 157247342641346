.LandingPage {
    &__box, &__box_small {
        @include box;
    }

    &__box_small {
        @include small-box;
    }

    &__our__fleet {
        img {
            width: 300px;
        }
    }
}