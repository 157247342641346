//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Nerko+One&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$primary: #eba23a;
$secondary: #797979;
$primary-background: #f6f6f6;

$font-family-base: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//$body-color: #7A7A7A;
//$heading-colors: #000;
$headings-font-weight: 700;
$default-font-size-px: 17px;

$font-sizes: (
  1: 5rem,
  2: 4rem,
  3: 2rem,
  4: 1.5rem,
  5: 1.2rem
);